<template>
  <ml-container>
    <div>

      <div v-if="items.length">
        <!-- Booked vendor cards -->
        <div class="tw-mb-6">
          <div v-if="this.number_booked > 0">
            <h3 class="tw-text-black tw-font-semibold tw-mb-3 tw-leading-relaxed">{{$t('user.booked')}}</h3>
          </div>

          <div class="tw-grid tw-grid-cols-4 tw-gap-8" v-if="this.number_booked > 0">
            <div v-for="(item) in items.filter(item => item.booked)" :key="item.id">

              <div  @click.prevent="item.order ? setOrder(item.order):viewVendor(item)"
                    class="tw-bg-red-100 tw-no-underline hover:tw-border-red tw-group tw-border-gray-n0 tw-border tw-shadow-md tw-p-4 tw-flex tw-items-center tw-rounded-xl"
                    :class="item.order ? 'tw-cursor-pointer':'tw-cursor-pointer'">
                <div class="hidden-sm-and-down tw-flex-shrink-0 group-hover:tw-bg-gray-n0 group-hover:tw-text-red tw-bg-white
                  tw-text-gray-n3 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-mr-4 tw-rounded-xl">
                  <img width="14" height="14" :src="getCategoryImage(item.category.mdi)" alt="">
                </div>
                <div>
                    <span class="sm:tw-text-sm md:tw-text-sm tw-text-xs tw-text-black group-hover:tw-text-red tw-block tw-font-semibold tw-leading-relaxed tw-mb-0.5">
                      {{ item.category.name }}
                    </span>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                        class="tw-ml-auto tw-cursor-pointer"
                        v-bind="attrs"
                        v-on="on"
                        @click.prevent="(e) => removeBooking(item,0, e)"
                    >
                      <check-circle-icon class="md:tw-w-5 md:tw-h-5 tw-w-3 tw-h-3 tw-text-red" stroke-width="1" />
                    </div>
                  </template>
                  <span>Unselect this Vendor</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                        class="tw-ml-2 tw-cursor-pointer"
                        @click.prevent="addNotes(item,$event)"
                        v-bind="attrs"
                        v-on="on">
                      <edit-icon class="md:tw-w-5 md:tw-h-5 tw-w-3 tw-h-3 tw-text-black" stroke-width="1" />
                    </div>
                  </template>
                  <span>Notes</span>
                </v-tooltip>
              </div>

            </div>
          </div>

          <div
              v-if="this.number_booked === 0 && !loading"
              class="tw-bg-red tw-rounded-xl tw-justify-center tw-text-white tw-font-semibold tw-flex tw-py-5">
            Let's start booking your Dream Team!!!
          </div>
        </div>

        <!-- Need to book vendor cards -->
        <div class="tw-mb-6">
          <h3 class="tw-text-black tw-font-semibold tw-mb-3 tw-leading-relaxed">{{$t('user.need_to_book')}}</h3>
          <div class="tw-grid tw-grid-cols-4 tw-gap-8">
            <div v-for="(item) in items.filter(item => !item.booked)" :key="item.id">
              <router-link :to="{ name: 'feed', query: { category_in: item.category.id }}"
                           target="_blank"
                           class="tw-no-underline hover:tw-border-red tw-group tw-border-gray-n0 tw-border tw-shadow-md tw-p-4 tw-flex tw-items-center tw-rounded-xl">
                <div class="hidden-sm-and-down tw-flex-shrink-0 group-hover:tw-bg-red-100 group-hover:tw-text-red tw-bg-gray-n0 tw-text-gray-n3 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-mr-4 tw-rounded-xl">
                  <img width="14" height="14" :src="getCategoryImage(item.category.mdi)" alt="">
                </div>
                <div>
                  <span class="sm:tw-text-sm md:tw-text-sm tw-text-xs tw-text-black group-hover:tw-text-red tw-block tw-font-semibold tw-leading-relaxed tw-mb-0.5">
                    {{ item.category.name }}
                  </span>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        class="tw-ml-auto"
                        v-bind="attrs"
                        v-on="on"
                        @click.prevent="manuallyBookSelect(item,$event)"
                    >
                      <check-circle-icon class="md:tw-w-5 md:tw-h-5 tw-w-3 tw-h-3 tw-text-red" stroke-width="1" />
                    </span>
                  </template>
                  <span>Check this box if vendor already booked</span>
                </v-tooltip>
              </router-link>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Dialog for Vendor select -->
    <v-dialog
        v-model="dialog"
        persistent
        hide-overlay
        max-width="540px"
    >
      <div class="tw-bg-white tw-p-10">
        <span class="tw-text-xl tw-font-semibold tw-flex tw-gap-3.5 tw-items-center">
          <plus-circle-icon/>
          Add vendor manually
        </span>
        <span>
          <form>
            <label class="tw-mt-6 tw-mb-0 tw-block tw-w-full">
              <span class="tw-block tw-text-gray-n3">Name</span>
              <input v-model="manually.vendor_name" placeholder="Vendor's name" class="tw-mt-2 tw-mb-1 tw-w-full tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-border-2 tw-p-2 tw-my-2 focus:tw-outline-none focus:tw-border-secondarydark focus:tw-shadow-md">
            </label>
            <ml-error-msg class="tw-absolute" :msg="msg.name"/>

            <label class="tw-mt-6 tw-mb-0 tw-block tw-w-full">
              <span class="tw-block tw-text-gray-n3">Email</span>
              <input v-model="manually.vendor_email" placeholder="Vendor's email" class="tw-mt-2 tw-mb-1 tw-w-full tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-border-2 tw-p-2 tw-my-2 focus:tw-outline-none focus:tw-border-secondarydark focus:tw-shadow-md">
            </label>
            <ml-error-msg class="tw-absolute" :msg="msg.email"/>

            <label class="tw-mt-6 tw-mb-0 tw-block tw-w-full">
              <span class="tw-block tw-text-gray-n3">Website</span>
              <input v-model="manually.vendor_address" placeholder="Link to the vendor's website" required class="tw-mt-2 tw-mb-1 tw-w-full tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-border-2 tw-p-2 tw-my-2 focus:tw-outline-none focus:tw-border-secondarydark focus:tw-shadow-md">
            </label>
            <ml-error-msg class="tw-absolute" :msg="msg.address"/>

            <label class="tw-mt-6 tw-mb-0 tw-block tw-w-full">
              <span class="tw-block tw-text-gray-n3">Note</span>
              <input v-model="manually.comment" placeholder="Type a note here" required class="tw-mt-2 tw-mb-1 tw-w-full tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-border-2 tw-p-2 tw-my-2 focus:tw-outline-none focus:tw-border-secondarydark focus:tw-shadow-md">
            </label>
            <ml-error-msg class="tw-absolute" :msg="msg.comment"/>

          </form>

        </span>

        <span class="tw-mt-8 tw-flex tw-gap-4 ">
          <Button class="tw-w-full" type="s_dark" @click="manuallyBookClear()">Cancel</Button>
          <Button class="tw-w-full" type="p_dark" @click="manuallyBook()">Add</Button>
        </span>
      </div>
    </v-dialog>

    <!-- Dialog View Vendor -->
    <v-dialog
        v-model="dialogViewVendor"
        persistent
        hide-overlay
        max-width="600px"
    >

      <v-card
      >
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="dialogViewVendor=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Vendor Detail</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>

          <div class="tw-mt-4 tw-p-4">
            <p>Vendor: {{selectedVendor.vendor_name}}</p>
            <p>Address: {{selectedVendor.vendor_address}}</p>
            <p>Comment: {{selectedVendor.comment}}</p>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogViewVendor=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Dialog for Vendor Note -->
    <v-dialog
        v-model="dialogNote"
        persistent
        hide-overlay
        max-width="600px"
    >

      <v-card
      >
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="dialogNote=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Notes</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>

          <v-textarea
              class="tw-mt-2"
              outlined
              required
              label="My notes"
              v-model="selectedVendor.comment"
              no-resize
          >
          </v-textarea>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogNote=false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="book(selectedVendor,selectedVendor.booked)"
              :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Order view -->
    <v-dialog
        v-model="showOrder"
        fullscreen
        hide-overlay
        transition="slide-x-transition"
        origin="0 0"
    >

      <div class="tw-absolute tw-inset-0 tw-overflow-hidden">
        <div @click="showOrder = false" class="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>
        <div class="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full tw-pl-10 sm:tw-pl-16">
          <div class="tw-pointer-events-auto tw-w-full tw-ml-auto" style="min-width: 704px;max-width: 704px;">
            <ViewBooking v-if="this.showOrder" :item=this.selectedItem >
              <template v-slot:button>
                <div class="tw-ml-3 tw-flex tw-h-7 tw-items-center">
                  <button @click="showOrder = false" type="button"
                          class="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
                    <span class="tw-sr-only">{{ $t('general.close') }}</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </button>
                </div>
              </template>
            </ViewBooking>
          </div>
        </div>
      </div>

    </v-dialog>



  </ml-container>
</template>


<script>

import MlContainer from "@/components/layout/MlContainer";
import { CheckCircleIcon, EditIcon, PlusCircleIcon } from "vue-feather-icons";
import ViewBooking from '@/components/ViewBooking.vue'
import i18n from '@/i18n'
import Button from '@/components/Button.vue'
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
export default {
  components: {
    ViewBooking,
    MlContainer,
    CheckCircleIcon,
    EditIcon,
    PlusCircleIcon,
    Button,
    MlErrorMsg,
  },
  middleware: ['auth', 'user'],
  metaInfo() {
    return {
      title:i18n.t('user.navbar.my_vendors'),
    }
  },
  data() {
    return {
      error: "This field is required",
      showOrder: false,
      selectedItem: null,
      dialog: false,
      dialogNote: false,
      dialogViewVendor: false,
      selectedVendor: {
        comment: null
      },
      loading: false,
      items: [],
      index: 1,
      number_booked: 0,
      manually: {
        item: null,
        comment: '',
        vendor_email: '',
        vendor_name: '',
        vendor_address: '',
      },
      msg: {},
      valid: true,
      lazy: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      rules: [
        value => !!value || 'Required.',
        //v => v.length >= 8 || 'Min 8 characters',
      ],
    }
  },
  mounted() {
    this.getMyVendors();
  },

  methods: {
    setOrder(item) {
      //console.log(item);
      this.selectedItem = item;
      this.showOrder = true;
    },
    openNote(item){
      this.selectedVendor=item;
      this.dialogNote=true;
    },
    viewVendor(item){
      this.selectedVendor=item;
      this.dialogViewVendor = true;
    },
    showcasework(item){
      if(item.vendor) this.$router.push({ name: 'showcasework.show', params: { id: item.vendor.id }})
    },
    manuallyBookSelect(item,e) {

      if(e && e.stopPropagation) e.stopPropagation();

      this.manually.item = item;
      this.msg = {
        name: '',
        email: '',
        address: '',
        comment: '',
      };
      this.dialog = true;
    },
    async manuallyBook() {

      await this.validate();
      console.log(this.valid);
      if (!this.valid) return;

      this.manually.item.comment = this.manually.comment;
      this.manually.item.vendor_email = this.manually.vendor_email;
      this.manually.item.vendor_name = this.manually.vendor_name;
      this.manually.item.vendor_address = this.manually.vendor_address;

      await this.book(this.manually.item, 1);
      this.manuallyBookClear();

    },
    manuallyBookClear() {
      this.dialog = false;
      this.manually.item = '';
      this.manually.comment = '';
      this.manually.vendor_email = '';
      this.manually.vendor_name = '';
      this.manually.vendor_address = '';
      this.valid = true;

    },
    goto(refName) {
      var top = document.getElementById(refName).offsetTop; //Getting Y of target element
      window.scrollTo(0, top);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getMyVendors() {
      this.loading = true;
      //let uri = '/api/events/' + this.$route.params.id;
      //let uri = '/api/events/1';
      let uri = '/api/events/myVendors';
      this.$http.get(uri)
          .then(({data}) => {
            this.items = data.data;
            this.number_booked = this.items.filter(item => item.booked).length;
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false,
      )

    },
    clearBooking() {
      let uri = '/api/events/myVendors';
      this.$http.delete(uri)
          .then((response) => {
            this.getMyVendors(),
                this.$toast.success(response.data.message, "Success")
          });
    },
    book(value, booked) {

      value.booked = booked;
      let uri = '/api/events/' + value.event_id + '/vendors/' + value.id;
      this.$http.patch(uri, value)
          .then((response) => {
            this.getMyVendors(),
                this.$toast.success(response.data.message, "Success")
          });
      this.selectedVendor = {
        comment: null
      };
      this.dialogNote = false;

    },
    addNotes(item,e){
      if(e && e.stopPropagation) e.stopPropagation();
      this.selectedVendor=item;
      this.dialogNote=true;
    },
    removeBooking(value, booked, e) {
      if(e && e.stopPropagation) e.stopPropagation();
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'product',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.book(value, booked);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }],
        ]
      });
    },
    clear() {

      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'product',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.clearBooking();

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }],
        ]
      });
    },
    getCategoryImage(iconName) {
      try {
        return require('@/assets/img/categories/' + iconName + '.svg');
      } catch (e) {
        return null;
      }
    },
    validate(){
      if (!this.validateName(this.manually.vendor_name) || !this.validateEmail(this.manually.vendor_email) || !this.validateAddress(this.manually.vendor_address) || !this.validateComment(this.manually.comment)) {
        this.valid = false;
      }
      else
        this.valid = true;
    },
    validateEmail(value){
      if(value === '')
      {
        this.msg.email = 'This field is required';
        return false;
      } else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)))
      {
        this.msg.email = 'Invalid Email Address';
        return false;
      } else
      {
        this.msg.email = '';
      }
      return true;
    },
    validateName(value){
      if (value === '') {
        this.msg.name = 'This field is required';
        return false;
      } else
      {
        this.msg.name = '';
      }
      return true;
    },
    validateAddress(value){
      if (value === '') {
        this.msg.address = 'This field is required' ;
        return false;
      } else {
        this.msg.address = '';
      }
      return true;
    },
    validateComment(value){
      if (value === '')
      {
        this.msg.comment = 'This field is required' ;
        return false;
      } else {
        this.msg.comment = '';
      }
      return true;
    },

  },
  watch: {
    "manually.vendor_name"(value){
      this.manually.vendor_name = value;
      this.validateName(value);
    },
    "manually.vendor_email"(value){
      this.manually.vendor_email = value;
      this.validateEmail(value);
    },
    "manually.vendor_address"(value){
      this.manually.vendor_address = value;
      this.validateAddress(value);
    },
    "manually.comment"(value){
      this.manually.comment = value;
      this.validateComment(value);
    }
  },
}
</script>







